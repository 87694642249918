import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { useStaticQuery, graphql } from "gatsby"
import BreadCrumbs from '../components/common/breadcrumbs'

const Testimonials = ({ page }) => {

  const data = useStaticQuery(graphql`
    query {
      allNodeTestimonial(sort: {fields: field_testimonial_date, order: DESC}) {
        edges {
          node {
            id
            body {
              value
              format
              processed
              summary
            }
            created
            title
            field_testimonial_author_job
            field_testimonial_company_name
            field_testimonial_date
            path {
              alias
            }
          }
        }
      }
    }`)

  const breadcrumbs = {
    'Home': '/',
    'Testimonials': null,
  }

  return (
    <Layout pageTitle="Testimonials">
      <SEO title="Testimonials"
        description="Drupal Developer Toronto - please review my Drupal Developer Testimonials page" />
      <div className="page-testimonials">
        <div className="container is-desktop">
          <div className="page-breadcrumbs notification">
            <BreadCrumbs input={breadcrumbs} />
          </div>
          {
            data.allNodeTestimonial.edges.map(node => {
              return (
                <div className="teaser-testimonial" key={node.node.id} style={{ paddingBottom: `60px` }}>
                  <div className="teaser-title" style={{ width: `80%`, marginBottom: `20px` }}>
                    <Link to={node.node.path.alias} style={{ fontSize: `32px`, fontWeight: `900` }}>
                      {node.node.title}
                    </Link>
                    <div>
                      <i>{node.node.field_testimonial_author_job}</i>, <span>{node.node.field_testimonial_company_name}</span>
                    </div>
                  </div>
                  <div className="teaser-content columns">
                    <div className="column">
                      <div className="testimonial-contents" dangerouslySetInnerHTML={{ __html: node.node.body.value }}></div>
                      <div className="testimonial-actions">
                        <Link to={node.node.path.alias} style={{ textDecoration: `underline`, fontWeight: `bold` }}>
                          Read More
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })
          }
        </div>
      </div>
    </Layout>
  )
}
export default Testimonials



